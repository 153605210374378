<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="24">
        <h3 class="m-l-25">Шинээр бүртгүүлсэн сурагчдийн жагсаалт</h3>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="24" class="home_body p-20">
        <el-table v-loading="loading"
                :data="studentList" stripe style="width: 100%" height="550" size="mini">
            <el-table-column
              type="index">
            </el-table-column>
            <el-table-column label="Бүртгүүлсэн огноо">
              <template #default="scope">
                  <span style="margin-left: 10px">
                      <el-tag type="info"
                          effect="plain" size="mini">{{ timeSince(scope.row.created_at) }}</el-tag>
                  </span>
              </template>
            </el-table-column>  
            <el-table-column prop="name" label="Овог">
              <template slot-scope="scope">
                {{ scope.row.to_contract.student.name }}
              </template>
            </el-table-column>
            <el-table-column prop="last_name" label="Нэр">
              <template slot-scope="scope">
                {{ scope.row.to_contract.student.last_name }}
              </template>
            </el-table-column>
            <el-table-column prop="school" label="Сургууль">
              <template slot-scope="scope">
                {{ scope.row.to_contract.student.school }}
              </template>
            </el-table-column>
            <el-table-column prop="grade" label="Анги">
              <template slot-scope="scope">
                {{ scope.row.to_contract.student.grade }}
              </template>
            </el-table-column>
            <el-table-column prop="start_date" label="Эхлэх огноо">
            </el-table-column>
            <el-table-column prop="end_date" label="Дуусах огноо">
            </el-table-column>
            <el-table-column prop="remaining_time" label="Суух цаг">
            </el-table-column>
            <el-table-column prop="eelj" label="Ээлж">
            </el-table-column>
          <el-table-column
              prop="lesson_name"
              label="Хичээл"
              width="100"
              :filters="[{ text: 'Математик', value: 'mat' }, { text: 'Англи хэл', value: 'eng' }]"
              :filter-method="filterTag"
              filter-placement="bottom-end">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.lesson_name === 'mat' ? 'primary' : 'success'"
                  disable-transitions>{{scope.row.lesson_name}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="description" label="Тэмдэглэл">
              <template slot-scope="scope">
                <p v-if="scope.row.to_contract.student.student_note.length">{{ scope.row.to_contract.student.student_note[0].description }}</p>
                <p v-else class="disabled_text">хоосон</p>
        </template>
          </el-table-column>
          <el-table-column label="Үйлдэл" width="200">
              <template slot-scope="scope">
                <el-button @click="addNote(scope.row)" size="mini" type="primary" icon="el-icon-edit">
                  Тэмдэглэл үлдээх
                </el-button>
              </template>
              </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <StudentDetailDialog v-if="this.$root.studentDetailDialogVariable" :selectedStudent="selectedStudentData"/>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import StudentDetailDialog from '@/components/studentDetailDialog/studentDetailDialog.vue'

// import axios from 'axios'
export default {
  name: 'contractLessonList',
  data () {
    return {
      studentList: [],
      laoding: false,
      selectedStudentData: {}
    }
  },
  components: {
    StudentDetailDialog
  },
  created () {
    this.getContractLessonList()
  },
  methods: {
    addNote (data) {
      this.selectedStudentData = data
      this.$root.studentDetailDialogVariable = true
    },
    filterTag (value, row) {
      return row.lesson_name === value
    },
    getContractLessonList () {
      this.loading = true
      services.getContractLesson()
        .then((response) => {
          this.studentList = response.result
          this.loading = false
        })
    },
    timeSince (datestring) {
      const date = new Date(datestring)
      const seconds = Math.floor((new Date() - date) / 1000)

      let interval = Math.floor(seconds / 31536000)

      if (interval > 1) {
        return interval + ' жил'
      }
      interval = Math.floor(seconds / 2592000)
      if (interval > 1) {
        return interval + ' сар'
      }
      interval = Math.floor(seconds / 86400)
      if (interval > 1) {
        return interval + ' өдөр'
      }
      interval = Math.floor(seconds / 3600)
      if (interval > 1) {
        return interval + ' цаг'
      }
      interval = Math.floor(seconds / 60)
      if (interval > 1) {
        return interval + ' минут'
      }
      return Math.floor(seconds) + ' секунд'
    }

  }
}

</script>
