<template>
    <div>
        <el-dialog :visible.sync="this.$root.studentNoteListDialog" :before-close="handleClose">
            <el-row class="page_head_zone">
                <el-col :xs="12" :sm="16" :md="18" :lg="20" :xl="20">
                <h3>Сурагчийн тэмдэглэл</h3>
                </el-col>
            </el-row>
            <el-form class="m-b-20">
                <el-form-item v-model="studentCreateNote">
                <el-input type="textarea" v-model="studentCreateNote.description" placeholder="Тэмдэглэл оруулах"></el-input>
                </el-form-item>
                <el-button type="success" size="mini" @click="saveStuentNote(studentCreateNote)">Хадгалах</el-button>
            </el-form>
            <el-table :data="student_note">
                <el-table-column label="№" type="index"></el-table-column>
                <el-table-column label="Тэмдэглэл" prop="description"></el-table-column>
                <el-table-column label="Бичсэн хүн">
                <template slot-scope="scope">{{ scope.row.teacher.name }}</template>
                </el-table-column>
                <el-table-column label="Огноо">
                <template slot-scope="scope">
                    {{ scope.row.created_at.split('T')[0] }}
                </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
import services from '@/helpers/services.js'
export default {
    data () {
        return {
            student_note: {
                teacher: {}
            },
            studentCreateNote: {
                description: ''
            }
        }
    },
    props: {
        student: Object
    },
    created () {
        this.getStudentNoteList()
    },
    methods: {
        getStudentNoteList(){
            // this.$root.fullscreenLoading = true
            const payload = {
                student_id : this.student.id
            }
            services.getStudentNote(payload)
            .then((response) => {
                if (response.status === 'success') {
                this.student_note = response.result
                this.$root.fullscreenLoading = false
                }
            })
        },
        handleClose () {
        this.$root.studentNoteListDialog = false
        },
        saveStuentNote (data) {
        this.$root.fullscreenLoading = true
        const payload = {
            student_id: this.student.id,
            valueDesc: data.description
        }
        services.createStudentNote(payload)
            .then((response) => {
            if (response.status === 'success') {
                this.$root.fullscreenLoading = false
                location.reload()
            }
            })
        }
    }
}
</script>
  