<template>
    <div>
        <el-dialog :visible.sync="this.$root.parentDetailDialog" :before-close="handleClose">
            <el-row class="page_head_zone">
                <el-col :xs="12" :sm="16" :md="18" :lg="20" :xl="20">
                <h3 class="m-l-25">Асран хамгаалагчийн мэдээлэл</h3>
                </el-col>
            </el-row>
            <el-table :data="parent">
                <el-table-column prop="name">
                    <template>
                        {{ parent.name }}
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>>
    </div>
</template>
<script>
import services from '@/helpers/services.js'
export default {
    data () {
        return {
            props: {
                student: Object
            },
            parent: {}
        }
    },
    methods: {
        created () {
            if(this.student){
                this.getParentDetail(this.$route.query.student_id)
            }
        },
        getParentDetail(data){
        this.$root.fullscreenLoading = true
        var payload = {
            student_id : data.id
        }
            services.getParent(payload)
            .then((response) => {
                this.parent = response.result
                console.log(response)
                if (response.status === 'success') {
                this.$root.fullscreenLoading = false
                }
            })
        },
        handleClose () {
        this.$root.parentDetailDialog = false
        },
    }
}
</script>
  